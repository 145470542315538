import React from "react"
import infusionsNourish from "../images/product-and-package/lip-infusions-nourish.jpg"
import infusionsHydration from "../images/product-and-package/lip-infusions-hydration.jpg"
import infusionsSoothing from "../images/product-and-package/lip-infusions-soothing.jpg"
import infusionsRestore from "../images/product-and-package/lip-infusions-restore.jpg"

import tescoLogo from "../images/stockists/tesco.png"
import superdrugLogo from "../images/stockists/superdrug.png"
import sainsburysLogo from "../images/stockists/sainsburys.png"
import morrisonsLogo from "../images/stockists/morrisons.png"
import ocadoLogo from "../images/stockists/ocado.png"
import amazonLogo from "../images/stockists/amazon.png"
import bootsLogo from "../images/stockists/boots.png"
//import wilkoLogo from "../images/stockists/wilko.png"

import everydayIcon from "../images/icons/product-icon/everyday.svg"
import outdoorsIcon from "../images/icons/product-icon/outdoors.svg"
import coldIcon from "../images/icons/product-icon/winter.svg"
import nightIcon from "../images/icons/product-icon/overnight.svg"

export const landingProducts = [
  {
    productImg: infusionsHydration,
    productTitle: "Lip Infusions - Hydration",
    productSubtitle: "Infused with Hyaluronic Spheres to lock in hydration",
    productDesc: [
      "Blistex Lip Infusions Hydration has a long-lasting lip care formula that provides perfectly balanced hydration to drench your lips in moisture without a heavy, greasy feel and is clinically shown to moisturise for up to 24 hours.",
      <ul>
        <li>Infused with Hyaluronic Spheres to lock in hydration</li>
        <li>Fortified with Vitamins E & F to soften and smooth</li>
        <li>Contains SPF 15 to protect against the sun's harmful UVB rays</li>
      </ul>,
    ],
    productLink: "/lip-infusions-hydration",
    disclaimer: "",
    icons: [
      { title: "everyday", icon: everydayIcon },
      { title: "outdoors", icon: outdoorsIcon },
      { title: "cold weather", icon: coldIcon },
      { title: "at night", icon: nightIcon },
    ],
    stockists: [
      {
        stockistName: "Boots",
        stockistLink:
          "https://www.boots.com/blistex-lip-infusions-hydration-10301404",
        stockistImage: bootsLogo,
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Tesco",
        stockistImage: tescoLogo,
        stockistLink:
          "https://www.tesco.com/groceries/en-GB/products/308826894",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Sainsbury's",
        stockistImage: sainsburysLogo,
        stockistLink:
          "https://www.sainsburys.co.uk/gol-ui/product/blistex-lip-infusions-hydration-spf15-37g",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Morrisons",
        stockistImage: morrisonsLogo,
        stockistLink:
          "https://groceries.morrisons.com/products/blistex-lip-infusions-hydration-spf-15-559044011?from=brand_shop&tags=%7C105651%7C102947%7C153405&parentContainer=%7C102947%7C153405",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Ocado",
        stockistImage: ocadoLogo,
        stockistLink:
          "https://www.ocado.com/products/blistex-lip-infusions-hydration-552888011",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Amazon",
        stockistImage: amazonLogo,
        stockistLink: "https://www.amazon.co.uk/dp/B09HS9RK11",
        classModifier: "",
        showLink: "true",
      },
    ],
  },
  {
    productImg: infusionsSoothing,
    productTitle: "Lip Infusions - Soothing",
    productSubtitle: "Infused with Cucumber Seed Oil and Calendula",
    productDesc: [
      "Blistex Lip Infusions Soothing has an advanced lip care formula that is gentle on your lips but tough on dryness, to soothe irritated lips and keep them soft, smooth and conditioned with maximum comfort.",
      <ul>
        <li>
          Infused with Cucumber Seed Oil and Calendula, known for their soothing
          properties
        </li>
        <li>
          With Natural Beeswax and Coconut Oil to moisturise and protect lips
        </li>
        <li>Dermatologist approved</li>
      </ul>,
    ],
    productLink: "/lip-infusions-soothing",
    disclaimer: "",
    icons: [
      { title: "everyday", icon: everydayIcon },
      { title: "at night", icon: nightIcon },
    ],
    stockists: [
      {
        stockistName: "Sainsbury's",
        stockistImage: sainsburysLogo,
        stockistLink:
          "https://www.sainsburys.co.uk/gol-ui/product/blistex-lip-infusions-soothing-37g",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Ocado",
        stockistImage: ocadoLogo,
        stockistLink:
          "https://www.ocado.com/products/blistex-lip-infusions-soothing-552889011",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Amazon",
        stockistImage: amazonLogo,
        stockistLink: "https://www.amazon.co.uk/dp/B09HSBHM2W",
        classModifier: "",
        showLink: "true",
      },
    ],
  },
  {
    productImg: infusionsNourish,
    productTitle: "Lip Infusions - Nourish",
    productSubtitle:
      "With Natural Beeswax and Coconut Oil to moisturise and protect lips",
    productDesc: [
      "Blistex Lip Infusions Nourish has an advanced lip care formula that delivers concentrated nourishment for luxuriously smooth lips. Infused with a blend of nutrient-enriched botanical oils and Vitamin E to soften without leaving a greasy feel.",
      <ul>
        <li>
          Ceramide-enhanced formula helps retain moisture, leaving lips supple
          and smooth
        </li>
        <li>
          With Natural Beeswax and Coconut Oil to moisturise and protect lips
        </li>
        <li>Contains SPF 15 to protect against the sun's harmful UVB rays</li>
      </ul>,
    ],
    productLink: "/lip-infusions-nourish",
    icons: [
      { title: "everyday", icon: everydayIcon },
      { title: "outdoors", icon: outdoorsIcon },
      { title: "cold weather", icon: coldIcon },
      { title: "at night", icon: nightIcon },
    ],
    stockists: [
      {
        stockistName: "Superdrug",
        stockistImage: superdrugLogo,
        stockistLink:
          "https://www.superdrug.com/Skin/Face-Skin-Care/Lip-Care/Lip-Balms/Blistex-Lip-Infusions---Nourish/p/805974?singleResultSearchPage=true",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Amazon",
        stockistImage: amazonLogo,
        stockistLink: "https://www.amazon.co.uk/dp/B09HSC2K85",
        classModifier: "",
        showLink: "true",
      },
    ],
  },
  {
    productImg: infusionsRestore,
    productTitle: "Lip Infusions - Restore",
    productSubtitle: "Infused with Squalane",
    productDesc: [
      "Blistex Lip Infusions Restore has an advanced lip care formula infused with a blend of ingredients that restores lips' moisture and leaves them feeling naturally soft and smooth again.",
      <ul>
        <li>
          Infused with Squalane, a hydrator known to help replenish the skin's
          moisture barrier
        </li>
        <li>
          Fortified with Allantoin, known to provide gentle and nonabrasive
          exfoliation that softens and smooths lips
        </li>
        <li>Dermatologist approved</li>
      </ul>,
    ],
    productLink: "/lip-infusions-restore",
    disclaimer: "",
    icons: [
      { title: "everyday", icon: everydayIcon },
      { title: "at night", icon: nightIcon },
    ],
    stockists: [
      {
        stockistName: "Boots",
        stockistLink:
          "https://www.boots.com/blistex-lip-infusions-restore-lip-balm-10317368",
        stockistImage: bootsLogo,
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Amazon",
        stockistImage: amazonLogo,
        stockistLink:
          "https://www.amazon.co.uk/Blistex-Infusions-Restore-Chapped-Coconut/dp/B09X61KCKS/ref=sr_1_6?crid=2NQHEXMB0T051&keywords=blistex+lip+infusions&qid=1654701142&sprefix=blistex+lip+in%2Caps%2C74&sr=8-6",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Tescos",
        stockistImage: tescoLogo,
        stockistLink:
          "https://www.tesco.com/groceries/en-GB/search?query=blistex",
        classModifier: "",
        showLink: "true",
      },
    ],
  },
]
