import React, { Component } from "react"
import Img from "gatsby-image"
import logo from "../../images/blistex-logo.svg"
import bgImg from "../../images/landing/product-hero-banner-landing-v03.jpg"

class HeroBannerHeader extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <section className="hero-banner-landing">
        <div class="container">
          <div
            className="logo"
            style={{ zIndex: "92", transform: "translate3d(0,0,0)" }}
          >
            <img src={logo} alt="Blistex Logo" />
          </div>
          <div
            className="hero-banner-landing__img"
            style={{
              backgroundImage: `url(${bgImg}),linear-gradient(179.98deg, #D1DBE2 0.02%, #C6D1D5 41.22%, #C5CFD3 44.96%, #BCC7C9 47.57%, #B9C3C2 49.84%, #BDC3C3 51.75%, #C7CBCC 53.84%, #D9DADC 56.72%, #DCDDDF 97.56%)`,
            }}
          >
            <div className="hero-banner-landing__title">
              <h1>{this.props.text.primaryTitle}</h1>
            </div>
          </div>
          <div className="hero-banner-landing__products">
            <Img fluid={this.props.data.productShot.childImageSharp.fluid} />
          </div>
          {this.props.text.secondaryTitle !== "" && (
            <div className="hero-banner-landing__subtitle">
              <div class="subtitle">
                <h2>{this.props.text.secondaryTitle}</h2>
                <div>Lip Infusions</div>
              </div>
            </div>
          )}
        </div>
      </section>
    )
  }
}

export default HeroBannerHeader
