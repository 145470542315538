import React, { Component } from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import HeroBannerHeader from "../components/landing-page/heroBanner"
import { StaticQuery, graphql } from "gatsby"
import ProductGrid from "../components/landing-page/productGrid"

class Products extends Component {
  state = {}
  render() {
    return (
      <Layout noHeaderInLayout={true} makeFooterSticky={false}>
        <SEO title="products" />

        <StaticQuery
          query={graphql`
            query {
              headerHeroImage: file(
                relativePath: { eq: "landing/product-hero-banner-landing.png" }
              ) {
                childImageSharp {
                  fluid(maxWidth: 3840) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              productShot: file(
                relativePath: { eq: "landing/infusions-group-shot.png" }
              ) {
                childImageSharp {
                  fluid(maxWidth: 3840) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
          render={data => (
            <HeroBannerHeader
              data={data}
              text={{
                primaryTitle: "Indulge your lips",
                secondaryTitle: [
                  "Advanced lip care formulas ",
                  <span>that infuse science and nature</span>,
                ],
              }}
            />
          )}
        />
        <ProductGrid />
      </Layout>
    )
  }
}

export default Products
