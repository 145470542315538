import React, { Component } from "react"
import { Link } from "gatsby"
import { landingProducts } from "../../data/landing-infusions"
class ProductGrid extends Component {
  render() {
    this.items = landingProducts.map((item, key) => (
      <div className="product-grid__item" key={key}>
        <img src={item.productImg} alt={item.productTitle} />
        <div className="product-grid__item-text">
          <div className="product-grid__title-wrapper">
            <h3>{item.productTitle}</h3>
          </div>
          <div className="product-grid__subtitle-wrapper">
            <h4>{item.productSubtitle}</h4>
          </div>
          <p>
            {item.productDesc}
            <span className="product-icon__container">
              {item.icons &&
                item.icons.map(icon => (
                  <span className="product-icon">
                    <img src={icon.icon} />
                    <span className="product-icon__tooltip">{icon.title}</span>
                  </span>
                ))}
            </span>
          </p>
          <h5 className="product-grid__wtb-title">Where to buy</h5>
          <div className="product-grid__wtb">
            <ul>
              {item.stockists.map((stockist, key) => (
                <li key={key}>
                  {stockist.stockistLink !== "" && (
                    <a
                      href={stockist.stockistLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        pointerEvents:
                          stockist.showLink !== "true" ? "none" : "all",
                      }}
                    >
                      <div className="stockistImg">
                        <img
                          src={stockist.stockistImage}
                          alt={stockist.stockistName}
                          title={stockist.stockistName}
                        />
                      </div>
                      <div className="cta-button cta-button--buy">buy now.</div>
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ))
    return (
      <section className="product-grid landing-grid">
        <div className="product-grid__container">{this.items}</div>
      </section>
    )
  }
}

export default ProductGrid
